import WithdrawIndex from '@/components/withdraw/WithdrawIndex'
import WithdrawView from '@/components/withdraw/WithdrawView'
import WithdrawCreate from '@/components/withdraw/WithdrawCreate'

import EarningsDashboard from '@/components/earnings/EarningsDashboard'

import AccountVideoFavorite from '@/components/account/video/AccountVideoFavorite'

import AccountInterestIndex from '@/components/account/interest/AccountInterestIndex'

import AccountCallIndex from '@/components/account/call/AccountCallIndex'
import AccountCallView from '@/components/account/call/AccountCallView'

import EarningsCurrency from '@/components/earnings/EarningsCurrency'
import EarningsCalculator from '@/components/earnings/EarningsCalculator'
import EarningsHistory from '@/components/earnings/EarningsHistory'

import SupportIndex from '@/components/support/SupportIndex'
import SupportTopic from '@/components/support/SupportTopic'
import SupportContent from '@/components/support/SupportContent'

import PublicVideoIndex from '@/components/public/video/PublicVideoIndex'

import UserVisitIndex from '@/components/userVisit/UserVisitIndex'

import VideoIndex from '@/components/video/VideoIndex'

import LivestreamIndex from '@/components/livestream/LivestreamIndex'
import LivestreamView from '@/components/livestream/LivestreamView'

import CreatorPaidShareIndex from '@/components/creator/paidShare/CreatorPaidShareIndex'
import CreatorPaidShareView from '@/components/creator/paidShare/CreatorPaidShareView'

import CreatorReview from '@/components/creator/review/CreatorReview'

import CreatorFineIndex from '@/components/creator/fine/CreatorFineIndex'
import CreatorGuideIndex from '@/components/creator/guide/CreatorGuideIndex'

import CreatorPendingCreditIndex from '@/components/creator/pendingCredit/CreatorPendingCreditIndex'
import CreatorRequestIndex from '@/components/creator/requests/CreatorRequestIndex'
import CreatorRequestManage from '@/components/creator/requests/CreatorRequestManage'

// need better deliniation between creator from guy or girl perspective
import CreatorRequests from '@/components/account/creatorRequests/CreatorRequests'
import CreatorRequestView from '@/components/account/creatorRequests/CreatorRequestView'

import SubscriptionIndex from '@/components/subscription/SubscriptionIndex'

import PostIndex from '@/components/post/index/PostIndex'

import CreatorInviteCreator from "@/components/creator/invite/CreatorInviteCreator";
import CreatorInviteActive from "@/components/creator/invite/CreatorInviteActive";
import CreatorInviteInactive from "@/components/creator/invite/CreatorInviteInactive";
import CreatorInviteInactiveMessage from "@/components/creator/invite/CreatorInviteInactiveMessage";
import CreatorInviteInactiveGallery from "@/components/creator/invite/CreatorInviteInactiveGallery";

import CreatorGalleryIndex from '@/components/creator/gallery/CreatorGalleryIndex'
import CreatorGalleryView from '@/components/creator/gallery/CreatorGalleryView'
import CreatorGalleryEdit from '@/components/creator/gallery/CreatorGalleryEdit'
import CreatorGalleryInvite from '@/components/creator/gallery/CreatorGalleryInvite'
import CreatorGalleryShare from '@/components/creator/gallery/CreatorGalleryShare'

import CreatorRuleIndex from '@/components/creator/rule/CreatorRuleIndex'

import CreatorCallIndex from '@/components/creator/call/CreatorCallIndex'
import CreatorCallView from '@/components/creator/call/CreatorCallView'

import CreatorBonusIndex from '@/components/creator/bonus/CreatorBonusIndex'

import CreatorLivestreamIndex from '@/components/creator/livestream/CreatorLivestreamIndex'
import CreatorLivestreamView from '@/components/creator/livestream/CreatorLivestreamView'

import CreatorPostIndex from '@/components/creator/post/CreatorPostIndex'
import CreatorPostSchedule from '@/components/creator/post/CreatorPostSchedule'
import CreatorPostView from '@/components/creator/post/CreatorPostView'
import CreatorPostEdit from '@/components/creator/post/CreatorPostEdit'

import JackpotIndex from '@/components/jackpot/JackpotIndex'

import CreatorVideoIndex from '@/components/creator/video/CreatorVideoIndex'
import CreatorVideoEdit from '@/components/creator/video/CreatorVideoEdit'

import LoginIndex from '@/components/login/LoginIndex'

import DeleteIndex from '@/components/delete/DeleteIndex'


// @DEPRECATED components below
// modernize as you go
// page model is deprecated, just use direct component now
// extra component that does nothing
import CreatorStatsPage from '@/page/creator/CreatorStatsPage'
import CreatorSubscribersPage from '@/page/creator/CreatorSubscribersPage'
import CreatorLeadboardPage from '@/page/creator/CreatorLeadboardPage'
import CreatorPage from '@/page/CreatorPage'
import CreatorInvitePage from '@/page/creator/CreatorInvitePage'

// shared between affiliate and social

import SearchIndex from '@/components/search/SearchIndex'
import SwipeWidget from '@/components/swipe/SwipeWidget'

import PublicPostPage from '@/page/PublicPostPage'
import DefaultPage from '@/page/DefaultPage'
import VideoPage from '@/page/VideoPage'

import AccountPage from '@/page/AccountPage'
import AccountMore from '@/page/AccountMore'
import GalleryPage from '@/page/GalleryPage'
import SandboxPage from '@/page/SandboxPage'
import GalleryIndex from '@/components/galleryIndex/GalleryIndex'


const routes = [
    {
        path: '/',
        component: DefaultPage,
        name: 'defaultPage',
        meta: { primaryActionLogin: true, public: true }
    },
    {
        path: '/feed',
        component: PostIndex,
        name: 'feed',
        meta: { primaryNavigation: 'feed', view: 'popular' },
    },
    {
        path: '/sandbox',
        component: SandboxPage,
    },
    {
        path: '/browse/search',
        component: SearchIndex,
        name: 'search',
        meta: { primaryNavigation: 'browse' },
    },
    {
        path: '/browse/gallery',
        component: GalleryIndex,
        name: 'galleries',
        meta: { primaryNavigation: 'gallery' },
    },
    {
        path: '/browse/swipe',
        component: SwipeWidget,
        name: 'swipe',
        meta: { primaryNavigation: 'swipe' }
    },
    {
        path: '/browse/videos',
        component: VideoPage,
        name: 'videos',
        meta: { primaryNavigation: 'browse' }
    },
    {
        path: '/video/:slug?',
        component: VideoIndex,
        name: 'videoIndex',
        meta: { primaryNavigation: 'video' }
    },
    {
        path: '/live',
        component: LivestreamIndex,
        name: 'livestreamIndex',
        meta: { primaryNavigation: 'live' }
    },
    {
        path: '/livestream/:id',
        component: LivestreamView,
        name: 'livestreamView',
        meta: { primaryNavigation: 'live' }
    },
    {
        path: '/public/video/index',
        component: PublicVideoIndex,
        name: 'publicVideoIndex',
        meta: { primaryNavigation: 'video', public: true }
    },
    {
        path: '/post/:slug',
        component: PublicPostPage,
        name: 'publicPost',
        meta: { primaryNavigation: 'post' }
    },
    {
        path: '/gallery/:id',
        component: GalleryPage,
        name: 'galleryView',
        meta: { primaryNavigation: 'gallery' }
    },
    {
        path: '/gallery',
        component: GalleryIndex,
        name: 'galleryIndex',
        meta: { primaryNavigation: 'gallery' }
    },
    {
        path: '/support',
        component: SupportIndex,
        name: 'supportIndex',
    },
    {
        path: '/support/topic/:id',
        component: SupportTopic,
        name: 'supportTopic',
    },
    {
        path: '/support/content/:id',
        component: SupportContent,
        name: 'supportContent',
    },
    {
        path: '/login',
        component: LoginIndex,
        name: 'login',
        meta: { primaryNavigation: 'login', mode: 'login', public: true },
    },
    {
        path: '/signup',
        component: LoginIndex,
        name: 'signup',
        meta: { primaryNavigation: 'login', mode: 'signup', public: true },
    },
    {
        path: '/creator',
        component: CreatorPage,
        name: 'creatorDashboard',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/invite',
        component: CreatorInvitePage,
        name: 'creatorInvite',
        meta: { primaryNavigation: 'creator' },
        children: [
            {
                path: 'active',
                component: CreatorInviteActive,
                meta: { inviteStatus: 'active', primaryNavigation: 'creator' },
            },
            {
                path: 'inactive',
                component: CreatorInviteInactive,
                meta: { inviteStatus: 'inactive', primaryNavigation: 'creator' },
                children: [
                    {
                        path: 'message',
                        component: CreatorInviteInactiveMessage,
                        meta: {
                            engagementMode: 'message',
                            inviteStatus: 'inactive',
                            primaryNavigation: 'creator'
                        },
                    },
                    {
                        path: 'gallery',
                        component: CreatorInviteInactiveGallery,
                        meta: {
                            engagementMode: 'gallery',
                            inviteStatus: 'inactive',
                            primaryNavigation: 'creator'
                        },
                    },
                ]
            },
            {
                path: 'creator',
                component: CreatorInviteCreator,
                meta: { inviteStatus: 'creator', primaryNavigation: 'creator' },
            },
        ]
    },
    {
        path: '/delete',
        component: DeleteIndex,
        name: 'deleteIndex',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/jackpot',
        component: JackpotIndex,
        name: 'jackpotIndex',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/review',
        component: CreatorReview,
        name: 'creatorReview',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/stats',
        component: CreatorStatsPage,
        name: 'creatorStats',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/leaderboard',
        component: CreatorLeadboardPage,
        name: 'creatorLeaderboard',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/rule/index',
        component: CreatorRuleIndex,
        name: 'creatorRuleIndex',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/call',
        component: CreatorCallIndex,
        name: 'creatorCall',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/call/:id',
        component: CreatorCallView,
        name: 'creatorCallView',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/livestream',
        component: CreatorLivestreamIndex,
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/livestream/:id',
        component: CreatorLivestreamView,
        name: 'creatorLivestreamView',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/video/index',
        component: CreatorVideoIndex,
        name: 'creatorVideoIndex',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/video/edit/:id',
        component: CreatorVideoEdit,
        name: 'creatorVideoEdit',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/post',
        component: CreatorPostIndex,
        name: 'creatorPost',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/post/schedule',
        component: CreatorPostSchedule,
        name: 'creatorPostSchedule',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/post/:id',
        component: CreatorPostView,
        name: 'creatorPostView',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/post/:id/edit',
        component: CreatorPostEdit,
        name: 'creatorPostEdit',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/bonus',
        component: CreatorBonusIndex,
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/gallery',
        component: CreatorGalleryIndex,
        name: 'creatorGallery',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/gallery/:id',
        component: CreatorGalleryView,
        name: 'creatorGalleryView',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/gallery/:id/edit',
        component: CreatorGalleryEdit,
        name: 'creatorGalleryEdit',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/gallery/:id/invite',
        component: CreatorGalleryInvite,
        name: 'creatorGalleryInvite',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/gallery/:id/share',
        component: CreatorGalleryShare,
        name: 'creatorGalleryShare',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/pendingCredit',
        component: CreatorPendingCreditIndex,
        name: 'creatorPendingCredit',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/paidShare',
        component: CreatorPaidShareIndex,
        name: 'creatorPaidShare',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/paidShare/:id',
        component: CreatorPaidShareView,
        name: 'creatorPaidShareView',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/fines',
        component: CreatorFineIndex,
        name: 'creatorFines',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/guides',
        component: CreatorGuideIndex,
        name: 'creatorGuides',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/requests',
        component: CreatorRequestIndex,
        name: 'creatorRequests',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/requests/:id',
        component: CreatorRequestManage,
        name: 'creatorRequestManage',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/creator/subscribers',
        component: CreatorSubscribersPage,
        name: 'creatorSubscribers',
        meta: { primaryNavigation: 'creator' },
    },
    {
        path: '/withdraw/index',
        component: WithdrawIndex,
    },
    {
        path: '/withdraw/view/:id',
        component: WithdrawView,
        name: 'withdrawView',
    },
    {
        path: '/withdraw/create/:id',
        component: WithdrawCreate,
        name: 'withdrawCreate',
    },
    {
        path: '/earnings',
        component: EarningsDashboard,
        name: 'earnings',
    },
    {
        path: '/earnings/calculator',
        component: EarningsCalculator,
        name: 'earningsCalculator',
    },
    {
        path: '/earnings/currency',
        component: EarningsCurrency,
        name: 'earningsCurrency',
    },
    {
        path: '/earnings/history',
        component: EarningsHistory,
        name: 'earningsHistory',
    },
    {
        path: '/account',
        component: AccountPage,
        name: 'account',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/more',
        component: AccountMore,
        name: 'accountMore',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/video/favorite',
        component: AccountVideoFavorite,
        name: 'accountVideoFavorite',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/userVisit/index',
        component: UserVisitIndex,
        name: 'accountUserVisitIndex',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/interest/inbound',
        component: AccountInterestIndex,
        meta: { primaryNavigation: 'account', mode: 'inbound' },
    },
    {
        path: '/account/interest/outbound',
        component: AccountInterestIndex,
        meta: { primaryNavigation: 'account', mode: 'outbound' },
    },
    {
        path: '/account/call/index',
        component: AccountCallIndex,
        name: 'accountCallIndex',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/call/view/:id',
        component: AccountCallView,
        name: 'accountCallView',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/requests',
        component: CreatorRequests,
        name: 'accountCreatorRequests',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/subscriptions',
        component: SubscriptionIndex,
        name: 'accountSubscriptions',
        meta: { primaryNavigation: 'account' },
    },
    {
        path: '/account/requests/:id',
        component: CreatorRequestView,
        name: 'accountCreatorRequestView',
        meta: { primaryNavigation: 'account' },
    },
];

export default routes;
